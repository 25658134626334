<!-- --------------------------------------------------------------------------------
                      LoginLogo2.vue

DESCRIPTIONS
  - Account Login: Email/Username, PWD, PIN
  - on RHS

---------------------------------------------------------------------------------- -->
<template>

  <!-- Height: orig=350, new 230  -->
  <v-card class="pa-2" width="370px" :height="boxH" style="background-color: rgba(255, 255, 255, 0.8) !important;">
    <v-sheet class="ml-2 transparent black--text text-left border-0 elevation-0 fontTitle">Login your account</v-sheet>
    <!-- <v-divider></v-divider>   -->

    <!----------------------- Username ------------------------>
    <v-card width="95%" height="40px" class="mx-auto mt-1 transparent elevation-3">
      <inputBox3 label="" message="Email / Username" width1="0%" width2="70%" 
      :value="UserNamePwd.username" @msgInputBox1="onLoginName" :pErrMsg="errUsr"/>
    </v-card>

    <!----------------------- Password ------------------------>
    <v-card width="95%" height="40px" class="mx-auto mt-5 transparent elevation-3">
      <inputBox3 :input="'password'" label="" message="Password" width1="0%" width2="70%" 
      :value="UserNamePwd.password" @msgInputBox1="onLoginPwd"  :pErrMsg="errPwd"/>
    </v-card>

    <!----------------------- OTP ----------------------------->
    <div v-if="bShowOtp" width="95%" height="80px" class="mx-auto mt-3 transparent">
    <v-card width="95%" height="40px" class="mx-auto mt-3 transparent">
      <OtpCard :pReset="iResetOtp" @OtpCard="onEmitOtpCard" class="ma-1 transparent"/>
    </v-card>
    <v-card width="95%" height="60px" class="mx-auto mt-0 pa-1 transparent">
      <v-sheet color="transparent black--text" class="fontN" style="font-style: normal; font-size: 0.7em;">
      <p>Please Check your email for the One-Time-Password (OTP). Enter the 6 digits OTP in the above boxes and press the LOGIN button.</p>        
      </v-sheet>
    </v-card>
    </div>


    <!----------------------- Login button -------------------->
    <!-- replace "sBtnLogin" by "Login" -->
    <v-card width="95%" height="30px" class="mx-auto mt-4 transparent border-0 elevation-0">
      <v-btn small width="100%" height="100%" color="red lighten-2" @click="onBtnLogin" 
      class="mx-auto mt-3 rounded-lg elevation-5 fontB">Login</v-btn>
      <p class="mt-1 text-center" style="font-family: Montserrat; font-size: 0.7em;">{{ sReqMsg }}</p>
    </v-card>

    <!----------------------- Cancel button -------------------->
    <v-card width="95%" height="30px" class="mx-auto mt-2 transparent border-0 elevation-0">
      <v-btn v-if="showBtnCancel" small width="100%" height="100%" color="red lighten-2" @click="onBtnCancel" 
      class="mx-auto mt-3 rounded-lg elevation-3 text-capitalize fontB">{{ sBtnCancel }}</v-btn>
    </v-card>

    <!----------------------- Create User --------------------->
    <!-- 
    <v-card width="95%" height="25px" class="mx-auto mt-10 transparent d-flex border-0 elevation-0">
      <v-btn x-small :class="cSmallBtn">Create User</v-btn>
      <v-spacer></v-spacer>
      <v-btn x-small :class="cSmallBtn">Forget Password</v-btn>
    </v-card>  -->

    <!----------------------- Progress Circle ---------------->
    <!-- <v-card v-if="bShowOtp" width="95%" height="25px" class="mx-auto mt-0 transparent">  -->
    <v-card width="95%" height="25px" class="mx-auto mt-0 transparent border-0 rounded-0 elevation-0">
      <v-overlay absolute :value="bCircle" opacity="0">  
        <v-progress-circular indeterminate color="blue" size="32"></v-progress-circular>
      </v-overlay> 
    </v-card> 

    <v-snackbar right v-model="snackBar.on" :color="snackBar.color" class="fontB">{{ snackBar.message }}</v-snackbar>

  </v-card>
</template>

<script>
import axios from 'axios'
import * as Ut from '@/js/ut.js'
import inputBox3 from '@/components/Common/InputBox3'
import OtpCard from '@/components/Common/OtpCard'
import cookies from '@/js/Cookie.js'
import * as Tbl from '@/js/tables.js'

  export default {
    name: 'LoginLogo2',
    components: {
      inputBox3, OtpCard
    },

    data () {
      return {
        axiosPathLogin: '/auth/login',
        userAdm: 0,
        authType: "OTP",     // OTP or BASIC_PW        
        cSmallBtn: "transparent red--text text-capitalize border-0 elevation-0",
        UserNamePwd: {username: "", password: ""},
        Otp: '',       
        bShowOtp: false,
        sBtnLogin: 'REQUEST OTP',
        sBtnCancel: 'Resend OTP',
        showBtnCancel: false,
        bCircle: false,
        text: '',
        boxH1: '230',
        boxH2: '350',
        boxH: this.boxH1,
        cancelTokenSource: null,
        snackBar: {'on': false, 'color': '', 'message': ''},
        snackTimeout: this.snackTimeDef,
        snackTimeDef: 3000,
        tmrObj: {},
        errUsr: '',
        errPwd: '',
        iResetOtp: 0,
        iReqOtp: 0,
        maxOtpRetry: 3,
        sReqMsg: ''
      }
    },

    methods: {
      onInputBox1 (xstr) {
        //console.log(xstr);
      },

      onLoginName (xName, xCode) {
        this.UserNamePwd.username = xName;
        if (this.UserNamePwd.username !== '') this.errUsr = '';
      },

      onLoginPwd (xPwd, xCode) {
        this.UserNamePwd.password = xPwd;
        if (this.UserNamePwd.password !== '') this.errPwd = '';
        if (xCode === "13") {
          this.onBtnLogin();
        }
      },

      // DESCRIPTION:  receive emit from OtpCard.vue
      onEmitOtpCard (xobj) {
        if (xobj === null) return;
        //console.log('onEmitOtpCard()', xobj.id, xobj.text);
        switch(xobj.id) {
          case "1": 
            // this.axiosSendOtp2FA(); 
            break; // finish entering 6 digits OTP code
          case "2": 
            // this.axiosLogin2fa(xobj.text);
            this.Otp = xobj.text;
            break;
          default: break;
        }
      },

      // DESCRIPTION:  login with OTP 2FA
      async onBtnLogin () {
        // -------------------- Login Username & Password ----------------------
        if (this.UserNamePwd.username === "") {
          this.errUsr = "Username/Email is required";
        }
        if (this.UserNamePwd.password === "") {
          this.errPwd = "Password is required";
        }
        if (this.UserNamePwd.username === "" || this.UserNamePwd.password === "") return;
        let vName = this.UserNamePwd.username.toUpperCase();
        let vPass = this.UserNamePwd.password.toUpperCase();
        let bRet = false;

        if (vName == "DEMO" && vPass == "DEMO" && process.env.VUE_APP_DEMO_PW === "1") {
          bRet = await this.axiosLogin(Ut.Login.username, Ut.Login.password);
          if (bRet) this.gotoHomePage();
          //this.$emit('LoginLogo2', vobj);   // bypass otp
          return;
        } else {
          // --------------------- OTP only ----------------------
          if (this.sBtnLogin === 'REQUEST OTP') {   //(1) request OTP code
            this.iReqOtp = 0;
            this.bCircle = true;
            let bRet = await this.axiosSendOtp2FA();
            if (bRet) {
              this.sBtnLogin = 'LOGIN';              
            }
          } else if (this.sBtnLogin === 'LOGIN') {  //(2) got OTP and login
            if (this.Otp.length === 6) {
              this.bCircle = true;
              let bRet = await this.axiosLogin2fa(this.Otp);  //send valid OTP
              if (bRet) {
                this.sBtnLogin = 'REQUEST OTP';
                this.gotoHomePage();
              } else {                              //(2b) reply incorrect OTP 
                if (this.iReqOtp >= this.maxOtpRetry) {
                  this.resetAll();
                } else {
                  this.resetOtp();
                  this.bShowOtp = false;
                  this.boxH = this.boxH1;
                }
              }
            } else if (this.Otp.length < 6 && this.Otp.length > 0) {
              this.snackStart('Please enter 6 digits OTP ', 'error', 5000);   // > 6 digits
              //console.log(this.Otp, ' length=',this.Otp.length);
            }
          } else if (this.sBtnLogin === "REQUEST OTP AGAIN") {  //(3) OTP wrong, request another OTP
            this.sReqMsg = `Request OTP (${this.iReqOtp})`;
            this.bCircle = true;
            let bRet = await this.axiosSendOtp2FA();
            if (bRet) {
              this.sBtnLogin = 'LOGIN';
            }                     
          }
        }
        this.bCircle = false;   
        // `Request OTP (${this.iReqOtp})`
      },

      // DESCRIPTION:  Cancel Login OTP
      onBtnCancel () {
        // remove progress circle
        //this.bShowOtp = true;
        this.resetAll();
      },

      resetOtp () {
        this.iResetOtp++;
        if (this.iResetOtp > 60000) this.iResetOtp = 0;
      },

      resetAll () {
        this.iResetOtp = 0;
        this.sReqMsg = "";
        this.UserNamePwd.username = "";
        this.UserNamePwd.password = "";
        this.bCircle = false;
        this.sBtnLogin = 'REQUEST OTP';
        this.bShowOtp = false;
        this.boxH = this.boxH1;
        if (this.cancelTokenSource !== null) {
          this.cancelTokenSource.cancel('Operation canceled');
        }
      },

      // --------------------------------------------------------
      //            [Axios]
      // --------------------------------------------------------      
      // ---------------------------------------------
      // DESCRIPTION:  (1) request OTP code from email
      async axiosSendOtp2FA () {
        const CancelToken = axios.CancelToken;
        this.cancelTokenSource = CancelToken.source();
        this.iReqOtp++;
        this.sReqMsg = `Request OTP (${this.iReqOtp})`;

        let vObj = {
          'method': 'POST',
          'url':  this.$config.apiBaseUrl + '/auth/send_otp_2fa',
          timeout: 5000,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json'
          },
          cancelToken: this.cancelTokenSource.token,
          'data': {
            "username": this.UserNamePwd.username,
            "password": this.UserNamePwd.password
          }
        }

        if (this.UserNamePwd.username === '' || this.UserNamePwd.password === '') {
          return;
        }

        try {
          let resp = await axios.request(vObj)
          if (resp !== null) {
            if (resp.data.status === 'SUCCESS') {
              // need to check authType:  BASIC_PW  or  OTP
              let authType = "";
              if (resp.data.data == null) authType = "OTP";
              else authType = resp.data.data.authType;
              if (authType == "OTP") {  // OTP
                this.boxH = this.boxH2;
                this.bShowOtp = true;
                this.sBtnLogin = "LOGIN";
                this.snackStart(resp.data.message, 'primary', 5000);
                return true;
              } else if (authType == "BASIC_PW") {
                await this.axiosLogin(this.UserNamePwd.username, this.UserNamePwd.password);
                this.gotoHomePage();
              }
            } else {
              console.log('#axiosSendOtp2FA(1) error: ', resp.data.message);  // server reply with error message
              this.snackStart(resp.data.message, 'error', 5000);
            } 
          } else {
            console.log('#axiosSendOtp2FA(2) error: receive null response');  // rx null response
          }
        } catch (error) {
          // go back to initial page
          if ( error.message.indexOf('400')) {
            // code 400 --> BAD Request (incorrect username / password)
            //console.log("##axiosSendOtp2FA(400)", this.UserNamePwd);
            this.UserNamePwd.username = "";
            this.UserNamePwd.password = "";
            this.snackTimeout = 5000;
            this.snackStart('User login fail, check your name & password', 'error', 5000);
          } else {
            console.log('#axiosSendOtp2FA(3b) error: ', error.message);
          }
          this.sBtnLogin = 'REQUEST OTP';
          this.boxH = this.boxH1;
          this.bShowOtp = false;
          this.bCircle = false;
          // this.showBtnCancel = true;
        }
        return false;
      },

      // ---------------------------------------------
      // DESCRIPTION:  (2) login and get token
      async axiosLogin2fa (rsp) {        
        const CancelToken = axios.CancelToken;
        this.cancelTokenSource = CancelToken.source();

        if (rsp.length < 6) {
          return;
        }
        let vObj = {
          'method': 'POST',
          'url':  this.$config.apiBaseUrl + '/auth/login_with_2fa',
          timeout: 5000,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json'
          },
          cancelToken: this.cancelTokenSource.token,
          'data': {
            "username": this.UserNamePwd.username,
            "password": this.UserNamePwd.password,
            "code": rsp
          }
        }
        try {
          let resp = await axios.request(vObj)
          if (resp != null) {
            let vStatus = resp.data.status;
            if (vStatus === 'SUCCESS') {
              let vData = resp.data.data;
              this.boxH = this.boxH1;
              this.bShowOtp = false;
              //this.$cookies.set("token", vData.token, Ut.SysConf.tokenExpire); 
              cookies.set("kk", vData.token, this.$config.COOKIE_EXPIRE);
              let vObj = Tbl.createUserInfo(vData);
              cookies.set("us", JSON.stringify(vObj), this.$config.COOKIE_EXPIRE); 
              cookies.set("aa", "Y", this.$config.COOKIE_EXPIRE);
              this.userAdm = vData.userType;

              // go to LoginPage then HomePage
              let vobj = {Name: this.UserNamePwd.username, Pwd: this.UserNamePwd.password, Pin: this.Otp};
              this.$emit('LoginLogo2', vobj);
              //this.sBtnLogin = 'REQUEST OTP';
              //this.bCircle = false;
              return true;
            } else {
              console.log('#axiosLogin2fa(1) error: ', resp.data.message);  // server reply with error message
            }
          } else {
            console.log('#axiosLogin2fa(2) error: receive null response');  // rx null response
          }
        } catch (error) {
          if ( error.message.indexOf('400')) {
            this.bCircle = false;
            this.sBtnLogin = `REQUEST OTP AGAIN`;
            // code 400 --> BAD Request (incorrect OTP)
            console.log('#axiosLogin2fa(3a) error: ', error.message);
            this.snackStart('OTP fail, check your email again for another OTP', 'error', 5000);
          } else {
            console.log('#axiosLogin2fa(3b) error: ', error.message);
          }
        }
        return false;
      },


// ---------------------------------------------
    // DESCRIPTION:  Login without OTP
    async axiosLogin(xName, xPw) {
      let vObj = {
        'method': 'POST',
        'url': this.$config.apiBaseUrl + this.axiosPathLogin,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json'
        },
        'data': {
          //"password":  Ut.Login.password,
          //"username": Ut.Login.username
          "password": xPw,
          "username": xName
        }
      }

      this.authType = "";
      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          // alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: [{}, {}, ...] }
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS' && resp.data.data !== null) {
            let vData = resp.data.data;

            cookies.set("kk", vData.token, this.$config.COOKIE_EXPIRE);
            let vObj = Tbl.createUserInfo(vData);
            cookies.set("us", JSON.stringify(vObj), this.$config.COOKIE_EXPIRE);
            cookies.set("aa", "Y", this.$config.COOKIE_EXPIRE);
            //this.userAdm = vData.userType;
            this.authType = vData.authType;

            //this.gotoHomePage();
            return true;
          } else {
            console.log('#axiosLogin(1) error: ', resp.data.message);  // message from server
          }
        } else {
          console.log('#axiosLogin(2) error: receive null response');
        }
      } catch (error) {
        console.error('#axiosLogin(3) error: ' + error.message);
      }
      return false;
    },


      gotoHomePage() {
        //this.$root.$emit('LoginPage', this.loginData);
        this.$router.push('/Dashboard');
      },    



      // ----------------------------------------------------------------------------
      //                   Ut
      // ----------------------------------------------------------------------------
      snackStart(xmsg, xcolor, xtime) {
        if (this.snackBar.on) {
          this.snackStop();
        }
        this.snackBar.message = xmsg;
        this.snackBar.color = xcolor;
        this.snackBar.on = true;
        if (xtime >= 1000) {
          this.tmrObj = setTimeout(this.snackTimerEvent, xtime);
        } else {
          this.tmrObj = setTimeout(this.snackTimerEvent, this.snackTimeout);
        }
      },

      snackTimerEvent() {
        this.snackBar.message = '';
        this.snackBar.on = false;
        this.tmrObj = null;
        this.snackTimeout = this.snackTimeDef;  //default timeout 3sec
      },

      snackStop() {
        clearTimeout(this.tmrObj);
        this.snackBar.message = '';
        this.snackBar.on = false;
        this.tmrObj = null;
      },
    },

    mounted () {
      // create obj to store query results
      //Ut.WriteUser(Ut.createUser());
      //Ut.WriteOtp2Fa(Ut.createOtp2fa());
      // this.UserNamePwd = Ut.UserNamePwd;
      this.bCircle = false;
    }
  }
</script>

<style scoped>

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

.fontTitle {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 1.2em;
  font-weight: bold;   
  color: black;
}

</style>

