<template>

  <!-- <v-card height="100%" class="rounded-0 img1" v-bind:style="{
    'background-image': 'url(' + require(`@/assets/Images/Background/LoginPage.png`) + ')',
    'background-size': 'cover, contain'
  }">   -->

  <v-card width="100%" height="100%" class="transparent ma-0 pa-0">

  <v-card height="100%" class="rounded-0 img1" 
    v-bind:style="{'background-image': 'url(' + require(`@/assets/Images/Background/LoginPage4.png`) + ')',
    'background-size': 'cover, contain', 'z-index': 0,
    'filter': 'blur(1px) brightness(100%)',
    'opacity': 0.95,
  }">
  </v-card>

    <v-container fluid fill-height class="pa-0 transparent elevation-0 overflow-hidden" style="position: absolute; top:0; z-index: 2;">
      <!--------------------- (1) Company Logo ---------------------------------->
      <!-- <v-card style="position: absolute; left: 950px; top: 150px;"> -->
      <v-row></v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="ml-8 pa-0 transparent elevation-0" align="center">
            <v-img :aspect-ration="1" max-width="300px" :src="logoImage" class="ml-n10 transparent"
            style="filter: contrast(130%)"></v-img>
          </v-card>
        </v-col>
      </v-row>

    <!--------------------- (2) Login Box (LHS) ------------------------>
    <v-row>
      <v-col cols="12">
        <v-card class="mt-n12 transparent elevation-0" align="center">
        <!-- <v-overlay :value="true" :opacity="opacity2"> -->
          <LoginLogo2 @LoginLogo2="onEmitLoginData" style="z-index: 3;"/>
        <!-- </v-overlay> -->
      </v-card>
      </v-col>
    </v-row>
    <v-row></v-row>

    <v-row>
      <!--------------------- (3) Bottom Text ------------------------>
      <v-col cols="12" align-self="end">
        <v-sheet class="text-center transparent white--text elevation-0 fontSmall" style="filter: contrast(130%); z-index: 3;">{{ title3 }}</v-sheet>
      </v-col>
    </v-row>

    </v-container>

    <!--------------------- (4) Bottom AED images ------------------------>
    <v-card class="ml-1 pb-2 transparent elevation-0" style="z-index: 1; position: absolute; bottom:0; left: 0;">
      <v-img :aspect-ration="1" max-width="250px" :src="AedImage" class="ma-0 transparent" style="z-index: 1;"></v-img>
    </v-card>

  </v-card>  
</template>

<script>
import axios from 'axios'
//import SnackBarCard from '@/components/Common/snackBarCard'
import LoginLogo1 from '@/components/LoginPage/LoginLogo1'
import LoginLogo2 from '@/components/LoginPage/LoginLogo2'
import * as Ut from '@/js/ut.js'
import cookies from '@/js/Cookie.js'
import * as Tbl from '@/js/tables.js'

export default {
  name: 'LoginPage',

  components: {
    LoginLogo1, LoginLogo2
  },

  data() {
    return {
      axiosPathLogin: '/auth/login',
      // bgImage: require("@/assets/Images/Background/LoginPage.png")
      logoImage: require("@/assets/Images/Background/CompanyLogo4.png"),
      AedImage: require("@/assets/Images/Models/C1C2-4.png"),
      loginData: { Name: '', Pwd: '', Pin: '' },
      absolute: true,
      value: true,
      opacity: 0,
      absolute2: true,
      value2: true,
      opacity2: 0.1,
      title3: Ut.SysPara.loginTitle3,
      cancelTokenSource: null,
      // snackBarData:  {"text": "", "color": "error", "show": false}
    }
  },

  methods: {

    onEmitLoginData(xobj) {
      /* let vName = xobj.Name.toUpperCase();
      let vPass = xobj.Pwd.toUpperCase();
      if (vName === "DEMO") {
        if (vPass === 'DEMO') {
          if (process.env.VUE_APP_DEMO_PW === "1") {
            Ut.SysConf.SimData = false;
            let vobj = {
              "password": Ut.Login.password,
              "username": Ut.Login.username
            }
            this.axiosLogin(vobj);
          }
          return;
        }
      }  //250204 */
    },

    gotoHomePage() {
      //this.$root.$emit('LoginPage', this.loginData);
      this.$router.push('/Dashboard');
    },


    // --------------------------------------------------------
    //            Axios
    // --------------------------------------------------------
    // 'Access-Control-Allow-Origin'
    // 'Access-Control-Allow-Credentials': true

    // ---------------------------------------------
    // DESCRIPTION:  Login without OTP
    async axiosLogin(xobj) {
      let vObj = {
        'method': 'POST',
        'url': this.$config.apiBaseUrl + this.axiosPathLogin,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json'
        },
        'data': {
          "password":  Ut.Login.password,
          "username": Ut.Login.username
        }
      }

      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          // alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: [{}, {}, ...] }
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS' && resp.data.data !== null) {
            let vData = resp.data.data;
            // write login data (token + user info) to global variables
            //ut.WriteToken(vData.token);
            //Ut.WriteUser(vData);

            /* this.$cookies.set("token", vData.token, Ut.SysConf.tokenExpire); 
            this.$cookies.set("us", vData, Ut.SysConf.tokenExpire);  */
            //this.$cookies.set("token", vData.token, Ut.SysConf.tokenExpire); 

            cookies.set("kk", vData.token, this.$config.COOKIE_EXPIRE);
            let vObj = Tbl.createUserInfo(vData);
            cookies.set("us", JSON.stringify(vObj), this.$config.COOKIE_EXPIRE);
            cookies.set("aa", "Y", this.$config.COOKIE_EXPIRE);

            this.gotoHomePage();
          } else {
            console.log('#axiosLogin(1) error: ', resp.data.message);  // message from server
          }
        } else {
          console.log('#axiosLogin(2) error: receive null response');
        }
      } catch (error) {
        console.error('#axiosLogin(3) error: ' + error.message);
      }
    },


    // ----------------------------------------------------------------------
    //              [SnackBar]
    // usage:  this.openSnackBar('Tag ID: ' + xtagid + '  is not found', 1)
    // ----------------------------------------------------------------------
    // snackBar (xmsg, xcolor) {
    //  this.snackBarData = {"text": xmsg, "color": xcolor, "show": true};
    //}

  },

  mounted() {
    //console.log('#LoginPage');  //debug
    this.$root.$emit('App_TopBar', false);  // notify TopBar.vue to hide
  }

}
</script>

<style scoped>
.img1 {
  width: 100%;
  height: calc(100vh - 50px);
}

.card-border-transparent{
  border-color: white !important;
}

/*
#idBackGnd::-webkit-scrollbar {
    display: none;
}
*/

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

.fontSmall {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  font-size: 0.9em;
  color: black;
}



</style>
