<!-- --------------------------------------------------------------------------------
                      MonnthPicker.vue

DESCRIPTIONS
  - label & input

Note:
  - parent container height >= 47px

Reference:  https://v2.vuetifyjs.com/en/components/date-pickers-month/#dialog-and-menu
---------------------------------------------------------------------------------- -->
<template>
  <!-- <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
    :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="date" type="month" :label="title" prepend-icon="mdi-calendar" 
         readonly v-bind="attrs" v-on="on" style="font-weight: bold;">
      </v-text-field>
    </template>
    <v-date-picker v-model="date" @change="onDateChange" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
    </v-date-picker>
  </v-menu> -->

  <v-card height="40" class="ma-0 pa-0 transparent">
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
      :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field dense solo v-model="date" :label="pTitle" :prepend-icon="pathMonth"
          readonly v-bind="attrs" v-on="on" class="fontN">
        </v-text-field>
      </template>
      <v-date-picker v-model="date" type="month"  @change="onDateChange" no-title scrollable>
        <v-btn text color="primary" @click="menu = false" class="text-capitalize fontB">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="onBtnReset" class="text-capitalize fontB">Reset</v-btn>
      </v-date-picker>
    </v-menu>
  </v-card>  

</template>

<script>
// import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCalendarMonth  } from '@mdi/js';

  export default {
    name: 'MonthPicker',

    components: {
      SvgIcon
    },

    props: {
      pTitle: {type: String, default: 'Select a month'},
      pReset: {type: Number, default: 0}
    },

    data () {
      return {
        // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        date: '',
        menu: false,
        pathMonth: mdiCalendarMonth
      }
    },

    methods: {

      // DESRIPTION:  handler of pressing OK button, 
      onBtnkOk (xdate) {
        this.$refs.menu.save(xdate);
        this.$emit('MonthPick', xdate);
      },

      // DESCRITPION:  handler of select a month
      onDateChange(xdate) {
        //console.log('date-picker', xdate);  //debug
        this.$refs.menu.save(xdate);
        this.$emit('MonthPick', xdate);
        this.menu = true;
      },

      // DESCRIPITON:  reset month filter
      onBtnReset () {
        this.date = "";
        this.$refs.menu.save(this.date);
        this.$emit('MonthPick', "");
        this.menu = false;
      }

    },

    watch: {
      pReset: function (newVal) {
        this.onBtnReset();
        this.$emit('MonthPick', "reset");
      }
    },

    mounted () {
      //this.date = moment(Date()).format("YYYY-MM");
      //this.$emit('MonthPick', this.date);
      this.date = "";  // disable initial value
    }
  }
</script>

<style scoped>
.cBorder {
  border: 1px solid grey;
}

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

</style>
