<!-- --------------------------------------------------------------------------------
                      DoughNut3.vue

DESCRIPTIONS
  - 

Parameters:
  - 

Install
  - npm i vue-chartjs chart.js           // win7 needs to specify version
  - npm install chartjs-plugin-annotation  //text on Doughnut center
  - npm install chartjs-plugin-datalabels  //label on the chart bars

Reference:
  1) Chartjs
     https://www.npmjs.com/package/vue-chartjs
     https://vue-chartjs.org/guide/#chart-with-local-data
       https://vue-chartjs.org/examples/#vue-3-charts       ##

doughnut chart
https://www.npmjs.com/package/vue-chartjs
https://www.chartjs.org/docs/latest/charts/doughnut.html
Simple chart
https://stackblitz.com/github/apertureless/vue-chartjs/tree/main/sandboxes/doughnut?file=src%2FchartConfig.ts
https://www.chartjs.org/docs/latest/charts/doughnut.html

---------------------------------------------------------------------------------- -->
<template>
  <v-card id="idCard" :width="pWidth" :height="pHeight" class="pa-2 rounded-xl elevation-0" 
  style="background-color: inherit;"> 
  
    <Doughnut ref="refChart"  :data="chartData" :options="chartOptions" color="transparent" />
    
  
  </v-card>
</template>

<script>
import { Chart as ChartJS, Title, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'vue-chartjs'
//import * as chartConfig from '@/js/chartconfig.js'
ChartJS.register(Title, ArcElement, Tooltip, Legend)

import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ChartDataLabels);


  export default {
    name: 'DoughNut3',

    components: {
      Doughnut
    },

    props: {
      pWidth: {type: String, default: '400'},
      pHeight: {type: String, default: '400'},
      pTitle: {type: String, default: ''},
      pMessage: {type: String, default: ''},
      pData: {type: Array},
      pTrigger: {type: Number, default: 0},
    },

    data () {
      return {
        barColor: ['#FF4040', '#F4BE37', '#0EC541'],   //red yellow green
        chartData: this.createDataObj(),
        chartOptions: this.createOptionObj(),

        iSum: 0
      } //end of data
    },

    methods: {

      // DESCRIPTION:  build dataset and options, copy to chart
      setDataset () {
        this.iSum = 0;
        let vData = this.createDataObj();
        if (this.pData !== undefined && this.pData !== null && this.pData.length > 0) {
          for(let idx=0; idx < this.pData.length; idx++) {
            vData.labels.push(this.pData[idx].label);
            vData.datasets[0].data.push(this.pData[idx].data);
            this.iSum += this.pData[idx].data;
          }
          //console.log("##setDataset1", vData);
        } 
        else { this.iSum = -1; }     //null dataset ?
        let vOption = this.createOptionObj();
        if (this.iSum <= 0) {        //empty dataset ?
          vData.labels.push("Empty");
          vData.datasets[0].data.push(1);
          vData.datasets[0].backgroundColor = ["grey"];
          vOption.interaction = { mode: null };
        }
        this.chartData = vData;
        this.chartOptions = vOption;
      },

      // DESCRIPTION:  Doughnut Chart - Data
      createDataObj () {
        return {
          labels: [],
          datasets: [
            {
              //backgroundColor: ['#FF4040', '#F4BE37', '#0EC541'],
              backgroundColor: this.barColor,       //cannot use data.variable
              hoverBackgroundColor: this.barColor,
              data: [],       //cannot use data.variable
              borderWidth: 0,
              borderJoinStyle: 'round'
            }
          ]
        }
      },

      // DESCRIPTION:  Doughnut Chart - Option
      createOptionObj () {
        // split message into 2 lines if necessary
        let vMessage = this.pMessage;
        let txt1 = "", txt2="";
        let offset1 = vMessage.indexOf("#");
        if (offset1 > 0) {
          txt1 = vMessage.substring(0,offset1);
          txt2 = vMessage.substring(offset1+1);
        } else txt1 = vMessage;
        txt1 = txt1.replaceAll("1 devices", "1 device");
        // if empty dataset
        if (this.iSum == 0) {
          txt1 = "Data unavaliable";
          txt2 = "";
        }

        return {
          responsive: true,
          maintainAspectRatio: false,
          cutout: "72%",
          //hover: { mode: null },
          interaction: { mode: 'index' },      // 'neartest', 'index', null
          onClick: (evt, activeEls, chart) => {
            if (activeEls !== undefined && activeEls !== null && activeEls.length > 0) {
              this.$emit('ChartClick', activeEls[0].index);  //to Dashboard, which sector is clicked
            }
          }, 

          //events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],

          plugins: {
            id: 'myEventCatcher',
            legend: { display: false },

            // Text on the chart sectors
            datalabels: {
              display: true,
              color: 'black',
              font: {family: 'Arial', weight: 'bold', size: 12},
              align: 'center',
              anchor: 'center',
              formatter: (value, ctx) => {
                let total = 0;
                let percentage = "";
                try {
                  total = ctx.chart.getDatasetMeta(0).total;
                  if (total == 0 || isNaN(total) == true) return "";
                  percentage = (value * 100 / total).toFixed(0) + "%";
                } catch(err) {
                  return "";
                }
                //return percentage + '(' + value + ')';
                if (this.iSum == 0) return "";  // empty dataset, no label
                if (percentage !== "0%" && percentage != "NaN%") return percentage;  //remove 0%
                return "";
              },
            },

            tooltip: {
              //backgroundColor: "#227799"
              callbacks: {
                label: function (context) {
                  //context.label, context.parsed, context.datasetIndex, context.hoverBackgroundColor (array)
                  //context.dataset.data[context.dataIndex]
                  return `${context.parsed} pcs`;
                },
              },
            },

            // Text on the chart center
            annotation: {               
              annotations: {
                dLabel: {
                  type: 'doughnutLabel',
                  content: ({chart}) => [
                    this.pTitle, txt1, txt2
                  ],
                  font: [
                    {size: 20, family: 'Montserrat'}, 
                    {size: 12, family: 'Montserrat'}, 
                    {size: 12, family: 'Montserrat'}
                  ],
                  color: ['white', 'white', 'white']
                }
              }, 
            } //end of annotation
          },  //end of plugins
        }
      },

      // DESCRIPTION:  handle "click at legend"                       OBSOLETED
      // https://www.chartjs.org/docs/latest/configuration/legend.html#legend-label-configuration
      onClickLegend (event, legendItem, legend) {
        const index = legendItem.datasetIndex;
        const ci = legend.chart;
        if (ci.isDatasetVisible(index)) {
          ci.hide(index);
          legendItem.hidden = true;
        } else {
          ci.show(index);
          legendItem.hidden = false;
        }
        //ci.update();
      }
      // ref:  https://gist.github.com/chhumsina/9bcc54851d19123d4a849432696f4bda

    },


    watch: {
      pTrigger: function () {
        this.setDataset();
      }
    },

    mounted () {      
   

    },

  }
</script>


<!-- ========================================================================== -->
<style scoped>

.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: normal;   
  color: white;
}

.fontB {
  font-family: Montserrat; 
  font-style: normal; 
  font-weight: bold;   
  color: white;
}

.cBorder {
  border: 1px solid grey;
}

#idCard {
  /* background-image: linear-gradient(to top left, #37474F, #455A64, #546E7A); */
  background-color: #282828;
}

.v-list-item {
  min-height: 30px;
}

</style>



<!--------------------------------------------------------------------------

to do:
1)  disable chart sorting          // data is not sorted by default
2)  update data                    // OK


Reference:
1)  Home:   https://www.chartjs.org/

2)  Anotation plugins
Example 1 : Bar Chart annotation
https://www.chartjs.org/chartjs-plugin-annotation/latest/guide/types/label.html

Example2 :  Doughnut Chart annotation, mutli line, size, color ##
https://www.chartjs.org/chartjs-plugin-annotation/master/guide/types/doughnutLabel.html

https://www.chartjs.org/chartjs-plugin-annotation/latest/guide/


3)  Data Label
https://quickchart.io/documentation/chart-js/custom-pie-doughnut-chart-labels/

4)  chart.js JS version in vue2  ##
https://stackoverflow.com/questions/70611355/vue-chart-3-doughnut-charts-with-text-in-the-middle-trouble-registering-a-plu

5)  Legend
https://www.chartjs.org/docs/latest/configuration/legend.html#legend-label-configuration


            // https://www.chartjs.org/docs/latest/configuration/legend.html
            //   https://www.chartjs.org/docs/latest/configuration/legend.html#legend-label-configuration


Usage:
Chart.defaults.backgroundColor = '#9BD0F5';
Chart.defaults.borderColor = '#36A2EB';
Chart.defaults.color = '#000';


---------------------------------------------------------------------------->


