<!-- --------------------------------------------------------------------------------
                      dialogUserSetting.vue

Last update:  Oct 28, 2014

DESCRIPTIONS - system settings
  - Email Notification enable
  - Transmission Period:   Daily / Weekly / Monthly
  - Email to:    CC to:

Mindray system settings
  - Pads Expiration Alert:     Time:   1 month    <Save>
  - Email Sending Period:      Every Day / Every Week / Every Month / Stop    <Save>


---------------------------------------------------------------------------------- -->
<template>
  <v-card class="text-center">

      <v-dialog v-model="bDialog" content-class="transparent" width="700px" height="100%" class="transparent overflow-hidden">

        <!---------------------------------- Container Dialog --------------------------->
        <!-- <v-card width="100%" :height="hPage" class="rounded-xl overflow-hidden">  -->
        <v-container fluid fill-height class="ma-0 pa-0 white rounded-xl">
          
          <!------------------------------- (1) Title + Exit btn ------------------------>         
          <v-row width="100%" height="20px" class="ma-0 pa-0 rounded-b-0 rounded-t-lg" style="background-color: #546E7A;">
            <!-- <v-Sheet width="100%" height="100%" class="ma-0 pa-0 border-0 rounded-t-lg rounded-b-0 text-right" style="background-color: #546E7A;"> -->
            <v-layout justify-center class="pt-1 white--text fontB1">{{ pTitle }}</v-layout>
            <v-btn icon small color="white" @click="bDialog= false" class="pa-0 text-right">
              <svg-icon type="mdi" :path="pathClose" size="24"></svg-icon>
            </v-btn>             
            <!-- </v-Sheet> -->
          </v-row>
          
          <!------------------------------- (2) Upper Container --------------------------->   
          <v-row width="100%" height="20px" class="ma-0 pa-0 mb-1 bottomBorder">
            <v-sheet width="100%" height="100%" class="my-0 ml-2 pa-0 text-left fontLarge">Email Settings</v-sheet>
          </v-row>

          <!-- <v-divider inset width="100%" class="mt-2"></v-divider>  -->

          <!------------------------------- (2) Fault Notification Area ------------------->
          <!------------------------------- (2a) Fault Settings --------------------------->
          <v-row width="100%" height="20px" class="ma-0 pa-0 white">
            <v-col cols="6" class="ma-0 pa-0">
              <v-sheet class="ml-2 fontB2">Fault Notification</v-sheet>
            </v-col>
            <!-- <v-col cols="6" class="ma-0 pa-0" style="border-left: 1px solid #E0E0E0;">   -->
            <v-col cols="6" class="ma-0 pa-0" style="border-left: 1px solid #E0E0E0;">
              <v-sheet class="ml-2 fontB2">Email Transmission Settings</v-sheet>
            </v-col>            
          </v-row>

          <v-row width="100%" height="20px" class="ma-0 mb-3 pa-0 transparent">
            <v-col height="100%" cols="6" class="ma-0 pa-0">
              <v-row dense height="100%" class="ma-0 pa-0">
                <v-col cols="40%" class="ma-0 ml-6 pa-0"><v-sheet class="fontN">Enable</v-sheet></v-col>
                <v-col cols="60%" class="ma-0 pa-0">
                  <v-sheet height="20px" class="mx-0 mt-n1 pa-0">
                    <v-radio-group row dense height="100%" v-model="radioFault" mandatory class="ma-0 fontN">
                      <v-radio label="On" value="ON"></v-radio>
                      <v-radio label="Off" value="OFF"></v-radio>
                    </v-radio-group>                    
                  </v-sheet>  
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6" class="ma-0 pa-0" style="border-left: 1px solid #E0E0E0;">
              <v-row dense height="100%" class="ma-0 pa-0 d-flex">
                <v-col cols="40%" class="ma-0 ml-4 pa-0"><v-sheet class="fontN">Transmission Period</v-sheet></v-col>
                <v-col cols="60%" class="ma-0 pa-0">
                  <v-sheet width="140px" class="ma-0 mr-2 pa-0 rounded-lg OutlineBgColor1">
                  <v-select dense hide-details height="20px" v-model="selectPeriod" 
                    :items="itemPeriod" item-color="blue" :label="labelPeriod"
                    class="mt-0 mr-2 pa-0 pl-2 fontN rounded-lg"  @change="onChangeCmb" >
                  </v-select>  
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>            
          </v-row>

          <!------------------------------- (2b) Email Address ------------------------->
          <!-- <v-row class="ma-0 mt-1 px-0">
            <v-sheet width="100%" class="my-0 ml-4 pa-0 fontB2 transparent">Email Address</v-sheet>            
          </v-row>  -->

          <!----------- 2b1 Email To  ------------>
          <v-row width="100%" height="20" class="ma-0 pa-0">
            <v-col cols="3" height="100%" class="ml-6 mt-0 pa-0 fontN">Email To:</v-col>

            <v-col cols="6" height="20px" class="ma-0 pa-0">
              <v-sheet height="100%" class="ma-0 px-2 py-0 rounded-lg OutlineBgColor1">
              <v-text-field regular dense light width="100" height="100%" placeholder="email address" type="input" 
              v-model="sInputEm" hide-details :spellcheck="false"
              class="mt-0 text-decloration-none elevation-0 fontN">
              </v-text-field>
              </v-sheet>
            </v-col>

            <v-col cols="2" height="20px" class="ma-0 pa-0">
              <v-btn small width="90%" height="25px" class="ml-2 mt-0 pa-1 my-auto text-lowercase rounded 
              light-blue lighten-5 elevation-3 fontB3" @click="onBtnAddEmail">add email</v-btn>
            </v-col>
          </v-row>

          <v-row v-if="showEmail===true"  width="100%" class="ma-0 pa-0">         
            <v-sheet width="90%" height="100px" max-height="60px" class="mx-auto mt-2 rounded-lg overflow-auto" style="border: 1px solid grey;">
              <v-list dense color="transparent" class="ma-0 pa-0 fontN"> 
                <v-list-item-group>
                  <v-list-item v-for="(item, i) in aryEmail" :key="i" class="pa-0" @click="getListItemEm(i)" >
                    <v-list-item-content class="pa-0 ml-2">
                      <v-sheet class="d-flex">
                      <v-sheet class="ma-0 pa-0 black--text">{{ item }}</v-sheet>
                      <v-spacer></v-spacer>
                      <v-btn x-small height="18" color="blue" class="mb-1 mr-2 py-auto px-2 white--text 
                        text-capitalize rounded fontSmall" @click="onBtnDelEmail(i)">Delete</v-btn>
                      </v-sheet>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-sheet>
          </v-row>

          <!----------- 2b2 CC To  ------------>
          <v-row width="100%" height="20" class="ma-0 mt-1 pa-0">
              <v-col cols="3" height="100%" class="ml-6 mt-0 pa-0 fontN">cc to:</v-col>

              <v-col cols="6" height="100%" class="ma-0 pa-0">
                <v-sheet outlined height="100%" class="ma-0 px-2 py-0 rounded-lg OutlineBgColor1">
                <v-text-field regular dense light width="100" height="100%" placeholder="email address" type="input" v-model="sInputCC" hide-details
                  class="mt-0 text-decloration-none elevation-0 fontN transparent" :spellcheck="false">
                </v-text-field>
                </v-sheet>
              </v-col>

              <v-col cols="2" height="20px" class="ma-0 pa-0">
                <v-btn small width="90%" height="25px" class="ml-2 mt-0 pa-1 my-auto text-lowercase rounded
                light-blue lighten-5 elevation-3 fontB3" @click="onBtnAddCC">add cc</v-btn>
              </v-col>
          </v-row>

          <v-row v-if="showCC===true" class="ma-0 pa-0">         
            <v-sheet width="90%" height="100px" max-height="60px" class="mx-auto mt-2 rounded-lg overflow-auto" style="border: 1px solid grey;">
              <v-list dense color="transparent" class="ma-0 pa-0 fontN"> 
                <v-list-item-group>
                  <v-list-item v-for="(item, i) in aryCC" :key="i" class="pa-0" @click="getListItemCC(i)">
                    <v-list-item-content class="pa-0 ml-2">
                      <v-sheet class="d-flex">
                      <v-sheet class="ma-0 pa-0 black--text">{{ item }}</v-sheet>
                      <v-spacer></v-spacer>
                      <v-btn small height="18" color="blue" class="mb-1 mr-2 py-auto px-2 white--text 
                        text-capitalize rounded fontSmall" @click="onBtnDelCC(i)">Delete</v-btn>
                      </v-sheet>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-sheet>
          </v-row>

          <!------------------------------ Rescue ----------------------------------->
          <!-- <v-divider inset class="mt-3"></v-divider>  -->

          <v-row width="100%" height="20px" class="ma-0 mt-2 pa-0 topBorder">
            <v-col cols="6" class="ma-0 pa-0">
              <v-sheet class="my-0 ml-2 fontB2">Rescue Notification</v-sheet>
            </v-col>          
          </v-row>
          
          <v-row width="100%" height="20px" class="ma-0 pa-0 transparent">
            <v-col height="100%" cols="6" class="ma-0 pa-0">
              <v-row dense height="100%" class="ma-0 pa-0">
                <v-col cols="40%" class="my-0 ml-6 pa-0"><v-sheet class="fontN">Enable</v-sheet></v-col>
                <v-col cols="60%" class="ma-0 pa-0">
                  <v-sheet height="20px" class="mx-0 mt-n1 pa-0">
                    <v-radio-group row dense height="100%" v-model="radioRescue" mandatory class="ma-0 fontN">
                      <v-radio label="On" value="ON"></v-radio>
                      <v-radio label="Off" value="OFF"></v-radio>
                    </v-radio-group>                    
                  </v-sheet>  
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row width="100%" height="20" class="ma-0 mt-1 pa-0">
            <v-col cols="3" height="100%" class="ml-6 mt-0 pa-0 fontN">Rescue cc to:</v-col>

              <v-col cols="6" height="100%" class="ma-0 pa-0">
              <v-sheet outlined height="100%" class="ma-0 px-2 py-0 rounded-lg OutlineBgColor1">
              <v-text-field regular dense light width="100" height="100%" placeholder="email address" type="input" v-model="sInputResc" hide-details
                class="mt-0 text-decloration-none elevation-0 fontN transparent" :spellcheck="false">
              </v-text-field>
              </v-sheet>
            </v-col>

            <v-col cols="2" height="20px" class="ma-0 pa-0">
              <v-btn small width="90%" height="25px" class="ml-2 mt-0 pa-1 my-auto text-lowercase rounded
                light-blue lighten-5 elevation-3 fontB3" @click="onBtnAddResc">add rescue</v-btn>
            </v-col>
          </v-row>

          <v-row v-if="showResc===true" class="ma-0 pa-0">         
            <v-sheet width="90%" height="100px" max-height="60px" class="mx-auto mt-2 rounded-lg overflow-auto" style="border: 1px solid grey;">
              <v-list dense color="transparent" class="ma-0 pa-0 fontN"> 
                <v-list-item-group>
                  <v-list-item v-for="(item, i) in aryRescue" :key="i" class="pa-0" @click="getListItemResc(i)">
                    <v-list-item-content class="pa-0 ml-2">
                      <v-sheet class="d-flex">
                      <v-sheet class="ma-0 pa-0 black--text">{{ item }}</v-sheet>
                      <v-spacer></v-spacer>
                      <v-btn small height="18" color="blue" class="mb-1 mr-2 py-auto px-2 white--text 
                        text-capitalize rounded fontSmall" @click="onBtnDelResc(i)">Delete</v-btn>
                      </v-sheet>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-sheet>
          </v-row>

                    
          <!------------------------------ Bottom ----------------------------------->
          <!-- <v-divider width="100%" inset class="mt-2 mb-1"></v-divider>  -->
          <v-row class="ma-0 mt-1 py-1 d-flex">
            <v-spacer></v-spacer>
            <v-sheet class="ma-0 pa-0 elevation-0 transparent">
            <v-btn small width="100px" height="30" @click="onBtnSave"
            class="ml-2 mr-9 pa-2 my-auto green white--text fontB2 text-capitalize rounded-lg elevation-3">Save</v-btn> 
            </v-sheet>
          </v-row>

        </v-container>
        <!-- </v-card>  -->

        <v-snackbar right v-model="snackBar.on" :color="snackBar.color" class="fontB2">{{ snackBar.message }}</v-snackbar>
      </v-dialog>

    </v-card>
</template>

<script>
import axios from 'axios'
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiClose } from '@mdi/js';

import cookies from '@/js/Cookie.js'
import inputBox2 from '@/components/Common/InputBox2'


  export default {
    components: {
      SvgIcon, inputBox2
    },

    props: {
      pTitle: {type: String, default: "Message"},
      pAct: {type: Boolean, default: false},
      aryDev: {type: Array}
    },

    data () {
      return {
        bDialog: false,
        hPageInit: 620,
        hPage: 620, // pagelength
        hOffset: 60,
        pathClose: mdiClose,
        urlEmail: '/client/api/scheduleEmail',
        btnClass: 'mt-1 ml-1 white--text text-capitalize',
        radioFault: "OFF",
        selectPeriod: "",
        labelPeriod: "",
        itemPeriod: ['daily', 'weekly', 'monthly'],
        radioRescue: "OFF",

        selectedItemEm: 0,  // email to
        selectedItemCC: 0,  // CC to
        selectedItemResc: 0,
        sInputEm: "",
        sInputCC: '',
        sInputResc: '',
        emailAcc: "", // current login user email addr
        aryEmail: [],
        aryCC: [],
        aryRescue: [],
        showCC: false,
        showEmail: false,
        showResc: false,
        snackBar: {"on": false, "color": "", "message": ""}
      }
    },

    methods: {
      // DESCRIPTION:  first function to call when entering this component
      btnOnDialog () {

      },

      onChangeCmb (xstr) {
        //console.log("##onChangeCmb", xstr, this.aryDev);  //debug
        this.labelPeriod = "";
      },

      // DESCRIPTION:  add to email address list, if not duplicated in aryEmail & aryCC
      onBtnAddEmail() {
        if (this.sInputEm.length === 0) return;
        let addr = this.sInputEm.trim();
        if (addr != "") {
          if (this.validateEmailAddr(addr)) {
            if (this.checkDuplicateAddr(addr, this.aryEmail) != true && this.checkDuplicateAddr(addr, this.aryCC) != true) {
              this.aryEmail.push(this.sInputEm);
              if (!this.showEmail) this.hPage = this.hPage + this.hOffset;
              this.showEmail = true;
            }
          }
        }
      },

      // DESCRIPTION: add to email cc list, if not duplicated in aryEmail & aryCC
      onBtnAddCC() {
        if (this.sInputCC.length === 0) return;
        //if (this.validateEmail(this.sInput)) {
        let addr = this.sInputCC.trim();
        if (addr !== "") {
          if (this.validateEmailAddr(addr)) {
            if (this.checkDuplicateAddr(addr, this.aryCC) != true && this.checkDuplicateAddr(addr, this.aryEmail) != true) {
              this.aryCC.push(this.sInputCC);
              if (!this.showCC) this.hPage = this.hPage + this.hOffset;
              this.showCC = true;
            }
          }
        }
      },

      // DESCRIPTION: add to email Rescue cc list
      onBtnAddResc() {
        if (this.sInputResc.length === 0) return;
        //if (this.validateEmail(this.sInput)) {
        let addr = this.sInputResc.trim();
        if (addr !== "") {
          if (this.validateEmailAddr(addr)) {
            if (this.checkDuplicateAddr(addr, this.aryRescue) != true) {
              this.aryRescue.push(this.sInputResc);
              if (!this.showResc) this.hPage = this.hPage + this.hOffset;
              this.showResc = true;
            }
          }
        }
      },      

      // DESCRIPTION:  delete selected email addr
      onBtnDelEmail(xidx) {
        //if (this.selectedItemEm >= 0 && this.selectedItemEm < this.aryEmail.length) {
        if (xidx >= 0 && xidx < this.aryEmail.length) {
          this.aryEmail.splice(xidx, 1);
          if (this.aryEmail === null) this.showEmail = false;
          else {
            if (this.aryEmail.length === 0) this.showEmail = false;
          }
          if (!this.showEmail) {
            this.hPage = this.hPage - this.hOffset;
          }          
        }
      },

      // DESCRIPTION:  delete selected email addr
      onBtnDelCC(xidx) {
        if (xidx >= 0 && xidx < this.aryCC.length) {
          this.aryCC.splice(xidx, 1);
          if (this.aryCC === null) this.showCC = false;
          else {
            if (this.aryCC.length === 0) this.showCC = false;
          }
          if (!this.showCC) {
            this.hPage = this.hPage - this.hOffset;
          }
        }
      },

      // DESCRIPTION:  delete selected email addr
      onBtnDelResc(xidx) {
        if (xidx >= 0 && xidx < this.aryRescue.length) {
          this.aryRescue.splice(xidx, 1);
          if (this.aryRescue === null) this.showResc = false;
          else {
            if (this.aryRescue.length === 0) this.showResc = false;
          }
          if (!this.showResc) {
            this.hPage = this.hPage - this.hOffset;
          }
        }
      },      

      getListItemEm(xidx) {
        this.selectedItemEm = xidx;
      },

      getListItemCC(xidx) {
        this.selectedItemCC = xidx;
      },

      getListItemResc(xidx) {
        this.selectedItemResc = xidx;
      },

      // DESCRIPTION:  save email addresses to SQL
      onBtnSave () {
        //this.axiosGetEmailInfo();
        this.axiosPostEmailInfo();
      },

      onBtnSendReport () {
        /* if (this.aryDev === null || this.aryDev.length === 0) {
          this.snackStart("error", "Please Select Device(s)");
          return;
        } 
        let arySn = [];
        for(let idx=0; idx < this.aryDev.length; idx++) {
          arySn.push(this.aryDev[idx].devSn);
        }
        let vObj = {
          //"sn": ["AE9-13000936","AE9-41008822T"],
          "sn": arySn,
          "email": {
            "emailName": "testUser",
            "to": this.emailTo,
            "cc": this.aryEmail.toString()
          }
        };
        if (this.aryEmail !== null && this.aryEmail.length === 0) {
          vObj.email.cc = "";
        }

        if (this.radioGroup === "1") {
          // Self Test report
          this.axiosEmailSelfTest(vObj);
        } else if (this.radioGroup === "2") {
          // Device Status report
          this.axiosEmailDevStatus(vObj);
        }  */
      },

      // DESCRIPTION:  validate an email address, return true or false or null
      // reference:  https://stackoverflow.com/questions/52456065/how-to-format-and-validate-email-node-js
      validateEmailAddr (xaddr) {
        if (xaddr == undefined || xaddr == "") return null;
        const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return emailRegexp.test(xaddr);
      },

      // DESCRIPTION:  return true if addr duplicated, null if not found
      checkDuplicateAddr(xaddr, xlst) {
        if (xlst == undefined || xlst == null) return null;
        for(let idx=0; idx < xlst.length; idx++) {
          if (xlst[idx] == xaddr) return true;   //find duplcate
        }
        return false;
      },

      //--------------------------------------------------------
      //          [axios]
      //--------------------------------------------------------      
      // DESCRIPTION:  get email info from SQL, executed at mount()
      async axiosGetEmailInfo () {
        let vObj = {
          'method': 'GET',
          'url': this.$config.apiBaseUrl + this.urlEmail,
          timeout: 3000,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('kk')
          }
        }

        try {
          let resp = await axios.request(vObj.url, vObj);
          if (resp != null) {
            let vStatus = resp.data.status;
            if (vStatus === 'SUCCESS') {
              if (resp.data.data !== undefined && resp.data.data !== null) {
                let ret = resp.data.data;
                if (ret.to === "") this.aryEmail = [];
                else this.aryEmail = ret.to.split(",");
                if (ret.cc === "") this.aryCC = [];
                else this.aryCC = ret.cc.split(",");
                if (ret.rescueCC === "") this.aryRescue = [];
                else this.aryRescue = ret.rescueCC.split(",");
                if (ret.enable) this.radioFault = "ON";
                else this.radioFault = "OFF";
                if (ret.rescueEnable) this.radioRescue = "ON";
                else this.radioRescue = "OFF";
                if (ret.period !== undefined && ret.period !== "") {
                  this.itemPeriod.forEach((element) => {
                    if (element === ret.period) this.selectPeriod = element;
                  });
                }
                if (this.aryEmail.length > 0) this.showEmail = true;
                else {
                  this.showEmail = false;
                  this.hPage = this.hPage - this.hOffset;
                }
                if (this.aryCC.length > 0) this.showCC = true; 
                else {
                  this.showCC = false;
                  this.hPage = this.hPage - this.hOffset;
                }
                if (this.aryRescue.length > 0) this.showResc = true; 
                else {
                  this.showResc = false;
                  this.hPage = this.hPage - this.hOffset;
                }
              } 
            } else {
              console.log('#axiosGetEmailInfo(1) error: axios data request fail');
            }  
            this.showProgress = false;
          } else {
            console.log('#axiosGetEmailInfo(2) error: receive null data');
            this.showProgress = false;
          }
        } catch (error) {
          this.showProgress = false;
          console.error('#axiosGetEmailInfo(3) error: ', error.message);
          if (error.message.indexOf('401') > 0) {
            this.snackStart(this.snackMsgLogout, 'error');
            // this.$root.$emit('App_Logout', "115");
          } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
            this.snackStart("Cannot connect to Server", 'error');
          }
        }
      },

      // DESCRIPTION:  store email info to SQL, excuted at onBtnSave()
      async axiosPostEmailInfo () {
        let vObj = {
          'method': 'POST',
          'url': this.$config.apiBaseUrl + this.urlEmail,
          timeout: 3000,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('kk')
          },
          data: {"to": "", "cc": "", "period": "", "enable": 0, "rescueCC": "", "rescueEnable": 0 }
        }

        if (this.showEmail) vObj.data.to = this.aryEmail.toString();
        if (this.showCC) vObj.data.cc = this.aryCC.toString();
        if (this.showResc) vObj.data.rescueCC = this.aryRescue.toString();
        if (this.selectPeriod !== "") vObj.data.period = this.selectPeriod;
        if (this.radioFault === "ON") vObj.data.enable = 1;
        else vObj.data.enable = 0;
        if (this.radioRescue === "ON") vObj.data.rescueEnable = 1;
        else vObj.data.rescueEnable = 0;

        try {
          let resp = await axios.request(vObj.url, vObj);
          if (resp != null) {
            let vStatus = resp.data.status;
            if (vStatus === 'SUCCESS') {
              this.bDialog = false;   // save & close dialog
            } else {
              console.log('#axiosSendSelfTest(1) error: axios data request fail');
            }  
            this.showProgress = false;
          } else {
            console.log('#axiosSendSelfTest(2) error: receive null data');
            this.showProgress = false;
          }
        } catch (error) {
          this.showProgress = false;
          console.error('#axiosSendSelfTest(3) error: ', error.message);
          if (error.message.indexOf('401') > 0) {
            this.snackStart(this.snackMsgLogout, 'error');
            // this.$root.$emit('App_Logout', "115");
          } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
            this.snackStart("Cannot connect to Server", 'error');
          }
        }
      },


      //--------------------------------------------------------
      //          [snackBar]
      //--------------------------------------------------------
      onSnackTimeout() {
        this.snackBar.on = false;
      },

      snackStart(xcolor, xmsg) {
        this.snackBar= {"on": true, "color": xcolor, "message": xmsg};
        setTimeout(this.onSnackBarTimeout, 3000);
      }
    
    },

    watch: {
      pAct: function (xflag) {
        this.bDialog = xflag; 
      },

      bDialog: function (newValue) {
        if (!newValue) {
           this.$emit('DialogUserSettingClose');
        } else {
          //console.log("##dialogUserSetting-watch");  //debug*
          this.hPage = this.hPageInit;
          this.sInputEm = "";
          this.sInputCC = "";
          this.sInputResc = "";
          this.axiosGetEmailInfo();  // reload data at dialog startup
        }
      }
    },

    mounted() {
      //let vObj = this.$cookies.get("us");
      if (!cookies.exist('us')) return;
      let str = cookies.get('us');
      let vObj = JSON.parse(str);
      if (vObj !== null) {       
        if (vObj.email !== null) { 
          this.emailAcc = vObj.email;
          //this.aryEmail.push(vObj.email);  // CC email
        }
        else {
          if (vObj.email1 !== null) this.emailAcc = vObj.email1;
          else {
            if (vObj.email2 !== null) this.emailAcc = vObj.email2;
          }
        }
      }

      //this.emailTo = this.emailAcc;
      //this.selectPeriod = "Weekly";
      //this.selectedEmail = "Device Status";
      
    }  
  }
</script>

<style scoped>

.v-list-item { 
  min-height: 8px;
}



.fontN {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 0.9em;
  font-weight: normal;   
  color: black;
}

.fontLarge {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 1.2em;
  font-weight: bold;   
  color: black;
}

.fontB1 {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 1.0em;
  font-weight: bold;   
  color: black;
}

.fontB2 {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 0.9em;
  font-weight: bold;   
  color: black;
}

.fontB3 {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 0.8em;
  font-weight: bold;   
  color: black;
}

.fontSmall {
  font-family: Montserrat; 
  font-style: normal; 
  font-size: 0.7em;
  font-weight: 200;   
  color: black;
}



#idCard1 {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  overflow-y: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */  
}

#idCard1::-webkit-scrollbar {
    display: none;
}


/* Class - draw border on input box: v-text-field, v-select, and set box background color */
/* Component must contained in v-sheet, in order to use this class */
.OutlineBgColor1 {
  background-color: #F5F5F5;
  border: 1px solid black;
}

.topBorder {
  border-top: 1px solid #E0E0E0;
  width: 100%;
}

.bottomBorder {
  border-bottom: 1px solid #E0E0E0;
  width: 100%;
}


/* grey lighten-X:  (5) #FAFAFA, (4) #F5F5F5, (3) #EEEEEE, (4) #E0E0E0, (5)   */

</style>
